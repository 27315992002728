import React from 'react';

import SEO from '../components/seo';
import Container from '../components/container';
import Layout from '../components/layout';
import BackLink from '../components/backLink';
import PageHeader from '../components/pageHeader';

import './__styles__/success.module.css';
import img from '../images/success.svg';

const Success = () => (
  <Layout>
    <SEO title="Success!" />
    <PageHeader
      title="Get Help"
      subtitle="Speak with a dedicated assistant who can answer your questions."
    />
    <Container styleName="container">
      <h3>Flood Assistance Request</h3>
      <img src={img} alt="Thumbs up" />
      <p>
        Thank you! We’ve received your request and will follow up shortly to
        find a time to talk.
      </p>
      <BackLink />
    </Container>
  </Layout>
);

export default Success;
